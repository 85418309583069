import validate from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rol_a04254c83fa2be409a3c7796a24523a3/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rol_a04254c83fa2be409a3c7796a24523a3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "bankid-redirect-guard": () => import("/home/vsts/work/1/s/middleware/bankid-redirect-guard.js"),
  "check-login": () => import("/home/vsts/work/1/s/middleware/checkLogin.js"),
  i18n: () => import("/home/vsts/work/1/s/middleware/i18n.js")
}