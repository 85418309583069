import routerOptions0 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_ioredis@5.4.1_magicast@0.3.4_rol_a04254c83fa2be409a3c7796a24523a3/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  linkActiveClass: "menu__item--active",
  linkExactActiveClass: "menu__item--active--exact",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
}